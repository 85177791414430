import React from "react"

import Layout from "../components/layout"
import Image from "../components/image"
import Logo from "../components/logo"
import SEO from "../components/seo"
import uiuclogo from "../images/uiuclogo.png"
import Coursework from "../components/coursework.jsx"
import Professional from "../components/professional.jsx"
import Campus from "../components/campus.jsx"
import Projects from "../components/projects.jsx"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hello!</h1>
    <div style={{ maxWidth: `320px`, marginBottom: `1.45rem` }}>
      
      <Image />
    </div>
    <p>I am Matthew, an incoming Software Engineer at <a href= "https://www.microsoft.com/en-us"  target="_blank">Microsoft</a>.</p>
    
    <h2>Education</h2>
    <p style={{ maxWidth: `750px`}}><img src ={uiuclogo} style={{ maxWidth: `25px`, verticalAlign: `middle`, marginRight: `0.5rem`}}></img>studied Computer Engineering at the <a href= "http://illinois.edu/"  target="_blank">University of Illinois Urbana-Champaign</a>. <br></br> 
    
    <Coursework></Coursework>
    </p>
    <Professional></Professional>
    <Campus></Campus>
    <Projects></Projects>
    <footer>
          © {new Date().getFullYear()}, Built with
          {` `}
          <span role="img" aria-label="heart">❤️</span>
        </footer>
  </Layout>
  
)

export default IndexPage
