import React, {useState, Fragment} from 'react';
import useCollapse from 'react-collapsed';
import arrowUp from './arrowup.svg'
import arrowDown from './arrowdown.svg'
import uiuclogo from "../images/uiuclogo.png"
function Coursework() {
  const [isOpen, setOpen] = useState(false);
  const {getCollapseProps, getToggleProps} = useCollapse({isOpen});

  return (
    <Fragment>
      <p
        {...getToggleProps({
          onClick: () => setOpen(oldOpen => !oldOpen),
        })}
      >
      <b> {isOpen ? <p>Relevant Coursework <img src = {arrowUp} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></p> : <p>Relevant Coursework <img src = {arrowDown} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></p>}</b>
      </p>
      <ul>
        <li {...getCollapseProps()}><p>Algorithms & Models of Computation</p></li>
        <li {...getCollapseProps()}><p>Applied Linear Algebra</p></li>
        <li {...getCollapseProps()}><p>Applied Parallel Programming</p></li>
        <li {...getCollapseProps()}><p>Artificial Intelligence</p></li>
        <li {...getCollapseProps()}><p>Computer Organization & Design</p></li>
        <li {...getCollapseProps()}><p>Computer Systems Engineering</p></li>
        <li {...getCollapseProps()}><p>Data Mining</p></li>
        <li {...getCollapseProps()}><p>Data Structures</p></li>
        <li {...getCollapseProps()}><p>Database Systems</p></li>
        <li {...getCollapseProps()}><p>Differential Equations</p></li>
        <li {...getCollapseProps()}><p>Discrete Structures</p></li>
        <li {...getCollapseProps()}><p>Functional Programming</p></li>
        <li {...getCollapseProps()}><p>Game Development</p></li>
        <li {...getCollapseProps()}><p>Programming Languages & Compilers</p></li>
      </ul>
    </Fragment>
  );
}
export default Coursework;