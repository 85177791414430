import React, {useState, Fragment} from 'react';
import useCollapse from 'react-collapsed';
import arrowUp from './arrowup.svg'
import arrowDown from './arrowdown.svg'
import rplogo from "../images/rp.png"
import gitlogo from "../components/github.svg"
import uiuclogo from "../images/uiuclogo.png"
import "./c.css"

function Campus() {
  const [isOpen, setOpen] = useState(false);
  const {getCollapseProps, getToggleProps} = useCollapse({isOpen});

  return (
    <Fragment>
      <p
        {...getToggleProps({
          onClick: () => setOpen(oldOpen => !oldOpen),
        })}
      >
        {isOpen ? <h2>Campus Involvement <img src = {arrowUp} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></h2> : <h2>Campus Involvement  <img src = {arrowDown} style={{ maxWidth: `20px`, verticalAlign: `bottom`}}></img></h2>}
      </p>
      <ul>
        <li {...getCollapseProps()}><a href= "https://reflectionsprojections.org/"  target="_blank"><img src ={rplogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Staff</a> (Feb 2019 - Present)
          <p style={{ marginLeft: `2rem`}}>I am on the infrastructure development team for MechMania, the AI hackathon at Reflections | Projections, the annual UIUC student-run tech conference. The infrastructure handles matchmaking, team registration, tournament logic, and connecting teams.</p></li>
        <li {...getCollapseProps()}><a href= "https://grainger.illinois.edu/research"  target="_blank"><img src ={uiuclogo} style={{ maxWidth: `15px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Undergraduate Research Assistant</a> (Feb 2019 - Dec 2019)  
          <p style={{ marginLeft: `2rem`}}>I worked with Thong Nguyen under the advice of Prof. Jose Schutt-Aine at the University of Illinois Electromagnetics Laboratory as an Undergraduate Research Assistant to develop a web-based circuit simulator and assignment submission platform. Both tools will be used by ECE 400/500 level courses to enhance the educational experience. I presented my progress over the course of the Spring 2019 semester at the PURE Research Symposium. <a href = "https://drive.google.com/file/d/1JV-BU_2ffiN890bXkSRBDmohF0n9p3JO/view" target = "_blank">My Research Poster</a></p></li>
          <li {...getCollapseProps()}><a href= "https://www.cs.illinois.edu/"  target="_blank"><img src ={uiuclogo} style={{ maxWidth: `15px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Course Assistant</a> (Aug 2019 - Dec 2019)  
          <p style={{ marginLeft: `2rem`}}>I worked as a course assistant for CS 125 - Introduction to Computer Science. I assisted with a weekly lab section and held weekly office hours to help and engage newcomers to the computer science field.</p></li>
      </ul>
    </Fragment>
  );
}
export default Campus;