import React, {useState, Fragment} from 'react';
import useCollapse from 'react-collapsed';
import arrowUp from './arrowup.svg'
import arrowDown from './arrowdown.svg'
import hqtlogo from "../images/hqt.png"
import wonderatelogo from "../images/brand.png"
import c1logo from "../images/c1logo.png"
import msftlogo from "../images/Microsoft-Logo.png"
import "../styles/global.css"
function Professional() {
  const [isOpen, setOpen] = useState(false);
  const {getCollapseProps, getToggleProps} = useCollapse({isOpen});

  return (
    <Fragment>
      <p
        {...getToggleProps({
          onClick: () => setOpen(oldOpen => !oldOpen),
        })}
      >
        {isOpen ? <h2>Professional Experience <img src = {arrowUp} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></h2> : <h2>Professional Experience <img src = {arrowDown} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></h2>}
      </p>
      <ul class="no-bullets">
        <li {...getCollapseProps()}><a href= "https://www.microsoft.com/en-us"  target="_blank"><img src ={msftlogo} style={{ maxWidth: `50px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Microsoft</a> (Aug 2022)
        <p style={{ marginLeft: `2rem`}}>I am an incoming Software Engineer at Microsoft.</p></li>
        <li {...getCollapseProps()}><a href= "https://www.capitalone.com/"  target="_blank"><img src ={c1logo} style={{ maxWidth: `50px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Capital One</a> (Jun 2021 - Aug 2021)
        <p style={{ marginLeft: `2rem`}}>At Capital One, I got experience working with machine learning for consumer & small business banking architecture. Specifically, I employed the use of word embedding and natural language processing techniques in a supervised machine learning task. Working with AWS, PyTorch, PySpark, Snowflake, and Databricks I trained models as well as performed cross-validation and hyperparameter tuning to increase the language models' accuracies. Additionally, I was able to give a talk on the basics of PyTorch to a panel of interns and engineers.</p></li>
        <li {...getCollapseProps()}><a href= "http://www.haywardquartz.com/"  target="_blank"><img src ={hqtlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Hayward Quartz Technology</a> (Jun 2019 - Aug 2019), (Jun 2020 - Aug 2020)
        <p style={{ marginLeft: `2rem`}}>As a Software Engineer Intern, I developed an inventory management platform using Java, MongoDB, and Microsoft Access (later updated using Electron & Node.js, with GUI testing done via Selenium), as well as worked with other engineering interns to measure and manage furance output data. Specifically, I created a GUI using Python and Tkinter.
        My applications are being used by employees on the production floor as well as supervisors and executives to make informed decisions and prepare the data for audits.</p></li>
    </ul>
    </Fragment>
  );
}
export default Professional;