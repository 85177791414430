import React, {useState, Fragment} from 'react';
import { Link } from "gatsby"
import useCollapse from 'react-collapsed';
import arrowUp from './arrowup.svg'
import arrowDown from './arrowdown.svg'
import gitlogo from "../components/github.svg"
import twitterlogo from "../components/twitter.svg"
import spotifylogo from "../images/spotify.png"
import lastfmlogo from "../images/lastfm.svg"
import arduinologo from "../images/arduino.png"
import pubnublogo from "../images/pubnub.png"
import delllogo from "../images/dell.png"
import twiliologo from "../images/twilio.png"
import c9logo from "../images/c9.png"
import riotlogo from "../images/riot.svg"
import netapplogo from "../images/netapp.png"
import uiuclogo from "../images/uiuclogo.png"
import "../styles/global.css"


function Projects() {
  const [isOpen, setOpen] = useState(false);
  const {getCollapseProps, getToggleProps} = useCollapse({isOpen});

  return (
    <Fragment>
      <link rel="stylesheet" href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"></link>
      <p
        {...getToggleProps({
          onClick: () => setOpen(oldOpen => !oldOpen),
        })}
      >
        {isOpen ? <h2>Projects <img src = {arrowUp} style={{ maxWidth: `20px`, verticalAlign: `middle`}}></img></h2> : <h2>Projects  <img src = {arrowDown} style={{ maxWidth: `20px`, verticalAlign: `bottom`}}></img></h2>}
      </p>
      <ul class="no-bullets">
        <li {...getCollapseProps()}>
        <div>
        <img src ={uiuclogo} style={{ maxWidth: `15px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>
          <Link to="/windows-9/">Windows 9 (ECE 391 MP3)</Link>
        </div><i class="devicon-c-plain colored"></i> <b>C</b>
        <p>A Linux-inspired Operating System built from scratch.</p>
        </li>
        <li {...getCollapseProps()}><a href= "https://github.com/matthew-pham/UITraffic"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>UI Traffic (CS 196-25 Semester Project)</a><br></br><br></br><b><i class="devicon-react-original colored"></i> React Native, <i class="devicon-php-plain colored"></i> PHP, <i class="devicon-mysql-plain colored"></i> MySQL, <i class="devicon-javascript-plain colored"></i> JavaScript, <i class="devicon-html5-plain colored"></i> HTML, <i class="devicon-css3-plain colored"></i> CSS</b>
            <p>Helping people avoid crowded areas for walking traffic purposes and predict ahead of time if a location will be populated to help make plans. For development purposes, the locations were notable areas around the University of Illinois at Urbana-Champaign's campus.</p></li>
        <li {...getCollapseProps()}><a href= "https://github.com/matthew-pham/SpotifyMusicRecommendation"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Spotify Music Recommendation</a><br></br><br></br><b><i class="devicon-java-plain colored"></i> Java, <img src ={spotifylogo} style={{ maxWidth: `21px`, verticalAlign: `top`, marginRight: `0.2rem`}}></img>Java Spotify Wrapper API, <img src ={lastfmlogo} style={{ maxWidth: `40px`, verticalAlign: `middle`, marginRight: `0.2rem`}}></img>last.fm REST API</b>
            <p>Creates a playlist of songs that a user is likely to enjoy based on an input of 5 songs. I am planning on adding some aspect of machine learning to this project.</p></li>
        <li {...getCollapseProps()}><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Perspective<br></br> Worked with: <a href="https://github.com/jamqd" target = "_blank">https://github.com/jamqd</a>
            <p>⭐EVHacks III Most Useful Hack Winner</p>
            <p>An website that takes a user's search query and finds 2 news articles with differing viewpoints about that topic and displays them side by side on the webpage. I wrote a custom MongoDB REST API for communication between the Java backend and the website's server.</p></li>
        <li {...getCollapseProps()}><a href= "https://github.com/KevinAndMatthewsProjects/IoTSmartHouse_HsHacks"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>IoTSmartHouse (HSHacks III)</a><br></br><br></br><b><img src ={arduinologo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.2rem`}}></img>Arduino, <img src ={pubnublogo} style={{ maxWidth: `35px`, verticalAlign: `bottom`, marginRight: `0.2rem`}}></img> PubNub, <i class="devicon-android-plain colored"></i> Android SDK</b>
            <p>Allows a user to control Internet of Things (IoT) devices using a mobile application. For development purposes, LED's wired to an Arduino represented devices. A basic implementation of machine learning (devices enabling/disabling based on a user's regular schedule) was also incorporated.</p></li>
        <li {...getCollapseProps()}><a href= "https://twitter.com/divmanickam/status/912090863395733504"  target="_blank"><img src ={twitterlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>IoT SmartHouse </a><br></br><br></br><b><img src ={delllogo} style={{ maxWidth: `60px`, verticalAlign: `bottom`, marginRight: `0.2rem`}}></img> Dell Boomi,<img src ={twiliologo} style={{ maxWidth: `35px`, verticalAlign: `middle`, marginRight: `0rem`}}></img>Twilio API, <img src ={c9logo} style={{ maxWidth: `35px`, verticalAlign: `middle`, marginRight: `0.2rem`}}></img>c9.io</b>
            <p>⭐API World 2017 - Largest API Integration Hackathon in the Nation - Dell Boomi Challenge 1st Place Winner</p>
            <p>Allows a user to control various IoT devices using an app on the Dell Boomi platform. I worked directly with Dell software engineers to develop a method to write a string to the screen of a Boomi application.</p></li>
        <li {...getCollapseProps()}><a href= "https://github.com/KevinAndMatthewsProjects/PositionPrediction"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Position Prediction (AP Calculus BC Final Project)</a><br></br><br></br><b><i class="devicon-java-plain colored"></i> Java</b>
            <p>Utilizes Euler's method to live approximate a projectile in order to predict where the projectile will land.</p></li>
        <li {...getCollapseProps()}><a href= "https://github.com/KevinAndMatthewsProjects/LeagueOfMasteries"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>LeagueOfMasteries (Riot Games 2017 API Challenge)</a><br></br><br></br><b><i class="devicon-java-plain colored"></i> Java, <i class="devicon-php-plain colored"></i> PHP, <img src ={c9logo} style={{ maxWidth: `35px`, verticalAlign: `middle`, marginRight: `0.2rem`}}></img>c9.io, <i class="devicon-html5-plain colored"></i> HTML, <i class="devicon-css3-plain colored"></i> CSS,<img src ={riotlogo} style={{ maxWidth: `40px`, verticalAlign: `middle`, marginRight: `0.0rem`}}></img>Riot Games API</b>
            <p>Recommends new characters for a League of Legends player to play based on their current mastery of characters.</p></li>
        <li {...getCollapseProps()}><a href= "https://github.com/KevinAndMatthewsProjects/Goals"  target="_blank"><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>Goals (Los Altos Hacks II)</a><br></br><br></br><b><img src ={c9logo} style={{ maxWidth: `35px`, verticalAlign: `middle`, marginRight: `0.2rem`}}></img>c9.io, <i class="devicon-php-plain colored"></i> PHP, <i class="devicon-html5-plain colored"></i> HTML, <i class="devicon-css3-plain colored"></i> CSS</b>
            <p>Allows a user to anonymously post their goals and receive positive feedback and encouragement from others, while managing their goals in a dashboard.</p></li>
        <li {...getCollapseProps()}><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>MusicByText<br></br><b><i class="devicon-java-plain colored"></i> Java, <img src ={twiliologo} style={{ maxWidth: `35px`, verticalAlign: `middle`, marginRight: `0rem`}}></img>Twilio API</b>
            <p>⭐EVHacks I Most Technical Hack Winner</p>
            <p>Allows a user to anonymously post their goals and receive positive feedback and encouragement from others, while managing their goals in a dashboard.</p></li>
        <li {...getCollapseProps()}><img src ={gitlogo} style={{ maxWidth: `25px`, verticalAlign: `top`, marginRight: `0.5rem`}}></img>IoT Traffic Analysis<br></br><b><i class="devicon-java-plain colored"></i> Java, <img src ={netapplogo} style={{ maxWidth: `20px`, verticalAlign: `middle`, marginRight: `0.2rem`}}></img>NetApp Cloud ONTAP for AWS</b>
            <p>⭐Integrate 2015 - Largest API + Integration Hackathon in the Nation - NetApp Challenge 2nd Place Winner</p>
            <p>An application that analyzes traffic data and can plan an efficient route through traffic for emergency response vehicles. With more time and resources, affecting traffic such as changing stop lights for the vehicle could be added.</p></li>
      </ul>
    </Fragment>
  );
}
export default Projects;